import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

// Connects to data-controller="widget-section-toggle"
export default class extends Controller {
  static targets = [ "button", "translate", "cross", "check" ]
  static values = {
    id: String,
    enabled: { type: Boolean, default: false },
    url: String
  }
  static classes = [
    "buttonDisabled", "translateDisabled", "crossDisabled", "checkDisabled",
    "buttonEnabled", "translateEnabled", "crossEnabled", "checkEnabled"
   ]

  connect() {
    if (this.enabledValue) {
      this.enable()
    } else {
      this.disable()
    }
  }

  async toggle(event) {
    event.preventDefault();

    const widgetUrl = this.urlValue

    this.enabledValue = !this.enabledValue

    if (this.enabledValue) {
      this.enable()
    } else {
      this.disable()
    }

    const request = new FetchRequest("PATCH", widgetUrl)
    const response = await request.perform()

    if (!response.ok) {
      // Reload page to revert to the previous state
      Turbo.visit(window.location.toString())
    }
  }

  enable() {
    // Remove the disabled class from the button
    this.buttonTarget.classList.remove(this.buttonDisabledClass)
    this.translateTarget.classList.remove(this.translateDisabledClass)
    this.crossTarget.classList.remove(this.crossDisabledClass)
    this.checkTarget.classList.remove(this.checkDisabledClass)

    // Add the enabled class to the button
    this.buttonTarget.classList.add(this.buttonEnabledClass)
    this.translateTarget.classList.add(this.translateEnabledClass)
    this.crossTarget.classList.add(this.crossEnabledClass)
    this.checkTarget.classList.add(this.checkEnabledClass)
  }

  disable() {
    // Remove the enabled class from the button
    this.buttonTarget.classList.remove(this.buttonEnabledClass)
    this.translateTarget.classList.remove(this.translateEnabledClass)
    this.crossTarget.classList.remove(this.crossEnabledClass)
    this.checkTarget.classList.remove(this.checkEnabledClass)

    // Add the disabled class to the button
    this.buttonTarget.classList.add(this.buttonDisabledClass)
    this.translateTarget.classList.add(this.translateDisabledClass)
    this.crossTarget.classList.add(this.crossDisabledClass)
    this.checkTarget.classList.add(this.checkDisabledClass)
  }
}
