import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["integrationSelect", "productSelect"]
  static values = {
    siteId: String,
    integrationId: String,
    configName: String
   }

  connect () {
    this.integrationIdValue = this.integrationSelectTarget.selectedOptions[0]?.value

    if (this.integrationIdValue) {
      this.fetchProducts()
    }
  }

  change(event) {
    this.integrationIdValue = event.target.selectedOptions[0].value

    if (this.integrationIdValue) {
      this.fetchProducts()
    } else {
      // TODO: Handle this nicely on the backend without having to do modify the DOM

      // get all options
      const options = this.productSelectTarget.options

      // clone the first option in a new const for later use
      const defaultOption = options[0].cloneNode(true)

      // change first option to "Select Integration" and value to ""
      defaultOption.text = "Select Integration"
      defaultOption.value = ""

      // Use only the first option
      this.productSelectTarget.innerHTML = ""
      this.productSelectTarget.appendChild(defaultOption)
    }
  }

  fetchProducts() {
    const target = this.productSelectTarget.id
    const configName = this.configNameValue

    get(`/sites/${this.siteIdValue}/integrations/${this.integrationIdValue}/freshdesk_products?target=${target}&config_name=${configName}`, {
      responseKind: "turbo-stream"
    })
  }
}
