import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static values = {
    conversationId: String,
  }

  connect() {
    this.subscription = consumer.subscriptions.create({ channel: "ConversationChannel", id: this.conversationIdValue }, {
      connected: this._connected.bind(this),
      disconnected: this._disconnected.bind(this),
      received: this._received.bind(this),
    })
  }

  disconnect() {
    this.subscription.unsubscribe()
  }

  _connected() {
  }

  _disconnected() {
  }

  _received(data) {
    if (!data.body) {
      return
    }

    const messageTarget = document.querySelector(`[data-conversations-message-body-id="message_${data.id}"]`)

    if (messageTarget) {
      messageTarget.innerHTML += data.body
    }
  }
}
