import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="inline-help-widget"
export default class extends Controller {
  static values = {
    userId: String,
    traits: Object,
    debug: { type: Boolean, default: false }
  }

  connect() {
    if (this.isPreview) {
      // do nothing on preview
      return
    }

    // Handle visit
    this.isFirstVisit ? this.handleFirstVisit() : this.handleVisit()

    // Update user id in localStorage
    localStorage.setItem(this.userIdStorageKey, this.userIdValue)
  }

  handleFirstVisit() {
    this.log("// First visit of the user")

    if (this.isUserLoggedIn) {
      this.log("// User is logged in")

      this.identifyUser()
    } else {
      this.log("// User is not logged in")

      // Nothing to do here if user is not logged in
    }
  }

  handleVisit() {
    const oldUserId = localStorage.getItem(this.userIdStorageKey)

    // User's previous state
    const userWasLoggedIn = (oldUserId != "")
    const userWasLoggedOut = (oldUserId == "")

    // user's current state
    const userLoggedIn = (this.userIdValue != "")
    const userLoggedOut = (this.userIdValue == "")

    // User was logged out; still logged out
    if (userWasLoggedOut && userLoggedOut) {
      this.log("// User was logged out; still logged out")

      this.resetUI()

      return
    }

    // User was logged out, now logged in
    if (userWasLoggedOut && userLoggedIn) {
      this.log("// User was logged out, now logged in")

      this.identifyUser()
      this.resetUI()

      return
    }

    // User was logged in; still logged in
    // User refreshed page or navigated to another page
    if (userWasLoggedIn && userLoggedIn && (oldUserId == this.userIdValue)) {
      this.log("// User was logged in; still logged in; User refreshed page or navigated to another page")

      // Here we have two cases combined:
      // 1. user refreshed page
      // 2. user navigated to another page

      // We need to identify user only for case 1 (user refreshed page)
      this.identifyUser()

      // We need to resetUI only for case 2 (user navigated to another page)
      this.resetUI()

      // We are doing both as we do not know which case it is.
      // It does not break anything calling both methods as they are idempotent.
      // But still would be good to find a way to distinguish between the two cases.
      // So adding a TODO here.
      // TODO: Find a way to distinguish between the two cases and call only the relevant method
      // PS: We might need identify on both cases in the future to pickup latest changes
      // if user updates their info and navigates to another page

      return
    }

    // User was logged in; now logged out
    if (userWasLoggedIn && userLoggedOut) {
      this.log("// User was logged in; now logged out")

      this.log("reset")
      window.InlineHelp.reset()

      return
    }
  }

  identifyUser() {
    this.log("identify")
    window.InlineHelp.identify(this.userIdValue, this.traitsValue)
  }

  resetUI() {
    this.log("resetUI")
    window.InlineHelp.resetUI()
  }


  log(message) {
    if (this.debugValue) {
      console.log("IH Widget:", message)
    }
  }

  get isPreview() {
    return document.documentElement.hasAttribute("data-turbo-preview");
  }

  // Consider as first visit if we do not have a user id in localStorage
  get isFirstVisit() {
    return (localStorage.getItem(this.userIdStorageKey) == null)
  }

  get isUserLoggedIn() {
    return (this.userIdValue != "")
  }

  get userIdStorageKey() {
    return "inline_help_widget_user_id"
  }
}
