import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="widget-section-link"
export default class extends Controller {
  static targets = [
    "urlSelection",
    "externalLink",
    "internalLink"
  ]

  static values = {
    linkType: String
   }

  connect() {
    const linkType = this.linkTypeValue

    console.info(linkType)

    if (linkType === "none") {
      this.selectNone()
    } else if (linkType === "external_link") {
      this.selectExternalLink()
    } else if (linkType === "internal_link") {
      this.selectInternalLink()
    }
  }

  selectNone() {
    // hide url selection
    this.urlSelectionTarget.classList.add("hidden")
  }

  selectExternalLink() {
    // show url selection which is common for both internal and external links
    this.urlSelectionTarget.classList.remove("hidden")

    // show external link selection
    this.externalLinkTarget.classList.remove("hidden")

    // hide internal link selection
    this.internalLinkTarget.classList.add("hidden")
  }

  selectInternalLink() {
    // show url selection which is common for both internal and external links
    this.urlSelectionTarget.classList.remove("hidden")

    // show internal link selection
    this.internalLinkTarget.classList.remove("hidden")

    // hide external link selection
    this.externalLinkTarget.classList.add("hidden")
  }
}
