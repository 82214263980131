import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

// Connects to data-controller="infinite-scroll"
export default class extends Controller {
  static targets = ["entries", "pagination", "trigger"]
  static values = {
    page: { type: Number, default: 1 }
  }

  connect () {
    // We do not need to show the pagination as we are using load more button
    this.paginationTarget.classList.add("hidden")

    const nextPage = this.paginationTarget.querySelector("a[rel='next']")

    if (nextPage == null) {
      // Hide load more button
      this.triggerTarget.classList.add("hidden")
    }
  }

  add() {
    const nextPage = this.paginationTarget.querySelector("a[rel='next']")

    if (nextPage == null) {
      return
    }

    this.loadMore(nextPage.href)
  }

  async loadMore(url) {
    const request = new FetchRequest("GET", url, {responseKind: "json"})
    const response = await request.perform()

    if (response.ok) {
      const data = await response.json
      this.entriesTarget.insertAdjacentHTML("beforeend", data.entries)
      this.paginationTarget.innerHTML = data.pagination

      const nextPage = this.paginationTarget.querySelector("a[rel='next']")

      if (nextPage == null) {
        // Hide load more button
        this.triggerTarget.classList.add("hidden")
      }
    }
  }
}
