import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="content-loader"
export default class extends Controller {
  load({ params: { url } }) {
    fetch(url)
      .then(response => response.text())
      .then(html => this.element.innerHTML = html)
  }

  loadContent(event) {
    event.preventDefault()

    const frameId = event.params.id
    const frame = document.getElementById(frameId)

    if (frame) {
      frame.src = event.params.url
      this.showLoadingIndicator(frameId)
    }
  }

  showLoadingIndicator(frameId) {
    const frame = document.getElementById(frameId)

    if (frame) {
      frame.innerHTML = `<p class="py-5 text-sm">Loading...</p>`
    }
  }
}
