import { Toggle } from "tailwindcss-stimulus-components"

// This is already implemented in latest code but its not released yet
// See https://github.com/excid3/tailwindcss-stimulus-components/blob/4c204ad245427f8dd9d57ea9c62a1a0c820f9c0f/src/toggle.js#L16
// TODO: Remove this controller when new version is released
export default class extends Toggle {
  // Sets open to value of checkbox or radio
  toggleInput(event) {
    this.openValue = event.target.checked
    this.animate()
  }
}
