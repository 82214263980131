import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="content-source-type-select"
export default class extends Controller {
  static targets = ["sourceType", "integration", "website"]

  connect() {
    this.showSettings()
  }

  showSettings() {
    this.hideAll()

    if (this.sourceTypeTarget.value === "integration") {
      this.show(this.integrationTarget)
    } else if (this.sourceTypeTarget.value === "website") {
      this.show(this.websiteTarget)
    }
  }

  hideAll() {
    this.hide(this.integrationTarget)
    this.hide(this.websiteTarget)
  }

  show(element) {
    element.classList.remove("hidden")
  }

  hide(element) {
    element.classList.add("hidden")
  }
}
