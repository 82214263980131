import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import { FetchRequest } from "@rails/request.js"

// Connects to data-controller="drag"
export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: ".handle",
      onEnd: this.end.bind(this)
    })
  }

  async end(event) {
    const id = event.item.dataset.id
    const data = new FormData()
    data.append("position", event.newIndex + 1)

    const url = this.data.get("url").replace(":id", id)

    const request = new FetchRequest("PATCH", url, { body: data })
    const response = await request.perform()

    if (!response.ok) {
      // Reload page to revert to the previous state
      Turbo.visit(window.location.toString())
    }
  }
}
