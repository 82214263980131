import {Controller} from "@hotwired/stimulus"

function checkInView(element, parent) {
  let parentTop = parent.scrollTop;
  let parentBottom = parentTop + parent.clientHeight;

  let elementTop = element.offsetTop - parent.offsetTop;
  let elementBottom = elementTop + element.clientHeight;

  return elementTop >= parentTop && elementBottom <= parentBottom;
}

export default class extends Controller {
  abortController = undefined;

  connect() {
    this.abortController = new AbortController();

    document.addEventListener("turbo:before-fetch-request", (event) => {
      event.preventDefault();

      localStorage.setItem("conversationsScrollTop", this.element.scrollTop);

      event.detail.resume();
    }, {
      signal: this.abortController.signal
    });

    const scrollTop = localStorage.getItem("conversationsScrollTop") || 0;

    this.element.scrollTo({top: scrollTop, behavior: 'instant'});

    const activeConversationId = this.element.getAttribute("data-active-conversation-id");
    const activeConversationElement = document.getElementById(`conversation_${activeConversationId}`);

    if (!activeConversationElement) {
      return;
    }

    const activeConversationElementFullyInView = checkInView(activeConversationElement, this.element);

    if (!activeConversationElementFullyInView) {
      activeConversationElement.scrollIntoView();
    }
  }

  disconnect() {
    this.abortController.abort();
  }
}
